<mat-card class="subscriptionInformation">
  <flex-row-between>
    <flex-col>
      <flex-row>
        <div class="subscriptionReference">{{ referenceCode }}</div>
        @if (subscription.internalOrderId) {
          <div class="subscriptionId">{{ subscription.internalOrderId }}</div>
        }
      </flex-row>
      @if (subscriptionType !== 'tsd' && subscriptionType !== 'chargeRepay') {
        <div class="SubscriptionSlotInfo">
          {{ 'USR_SETTINGS.GENERAL_SLOT_INFORMATION' | translate: { param1: usedSlots, param2: totalSlots } }}
        </div>
      }
      @if (subscriptionType === 'chargeRepay') {
        <div class="SubscriptionSlotInfo">
          {{ 'USR_SETTINGS.CHARGE_REPAY_SEAT_INFORMATION' | translate: { param1: usedSlots, param2: totalSlots } }}
        </div>
      }
    </flex-col>

    <flex-row class="subscriptionOptions">
      <div class="subscriptionType">
        {{ serviceName | translate }}
      </div>

      <div class="subscriptionMoreArea" (clickOutside)="showCancelOption = false">
        <app-icon (click)="showCancelOption = !showCancelOption" name="more" class="subscriptionMore" color="#333"></app-icon>
        <!-- cancel button -->
        @if (showCancelOption) {
          <pc-button
            class="cancelSubscriptionButton"
            variant="secondary"
            [disabled]="authorisation.isViewer(proficloud.currentOrganisation) || endDate || isFreeTSD"
            [text]="'USR_SETTINGS.SUBSCRIPTION_CANCEL_CANCEL' | translate"
            (btnClick)="
              billing.showCancelSubscriptionConfirm$.next({ subscription: subscription, type: subscriptionType }); showCancelOption = false
            "></pc-button>
        }
      </div>
    </flex-row>
  </flex-row-between>

  <mat-tab-group animationDuration="0ms" class="subs-tabs">
    <mat-tab label="Information">
      @if (endDate) {
        <div class="subscriptionCancelledBanner banner">
          <flex-row-start-center>
            <app-icon [name]="'error'" color="var(--jet-black)" class="bannerIcon"></app-icon>
            <div>{{ 'USR_SETTINGS.IN_CANCELLATION' | translate }} {{ endDate | date }}</div>
          </flex-row-start-center>
        </div>
      }

      @if (!endDate && totalSlots > usedSlots) {
        <flex-row-start-center class="infoBackground banner">
          <app-icon [name]="'error'" color="var(--jet-black)" class="bannerIcon"></app-icon>
          <div>
            {{ infoboxText | translate: { param: totalSlots - usedSlots } }}
          </div>
        </flex-row-start-center>
      }

      <div>
        <div class="subscriptionDetails">
          <span class="bold">{{ slotsText | translate }}</span>
          <div>
            {{ 'USR_SETTINGS.USED_SLOTS_SHORT' | translate: { param1: usedSlots, param2: totalSlots } }}
          </div>

          <span class="bold">{{ 'USR_SETTINGS.DESCRIPTION' | translate }}</span>
          <div>{{ description }}</div>

          <span class="bold">{{ 'USR_SETTINGS.SUBSCRIPTION_ID' | translate }}</span>
          <div>{{ referenceCode }}</div>

          <span class="bold">{{ 'USR_SETTINGS.REFERENCE_CODE' | translate }}</span>
          @if (subscription.internalOrderId) {
            <div>{{ subscription.internalOrderId }}</div>
          }
          @if (!subscription.internalOrderId) {
            <div>n.a.</div>
          }

          <span class="bold">{{ 'USR_SETTINGS.BOOKED_AT' | translate }}</span>
          @if (!isFreeTSD) {
            <div>{{ subscription.startDate | date }}</div>
          }
          @if (isFreeTSD) {
            <div>n.a.</div>
          }
        </div>
      </div>
    </mat-tab>
    @if (subscriptionType !== 'tsd' && subscriptionType !== 'chargeRepay') {
      <mat-tab label="Devices">
        <table class="DevicesTable">
          <tr>
            <td class="DevicesTableCell DevicesTableHeader">
              {{ 'USR_SETTINGS.DEVICES' | translate }}
            </td>
            <td class="DevicesTableCell DevicesTableHeader">
              {{ 'USR_SETTINGS.UUID' | translate }}
            </td>
          </tr>
          @if (devices.length === 0) {
            <tr>
              <td class="DevicesTableCell DevicesTableEmpty" colspan="2">
                {{ 'USR_SETTINGS.NO_DEVICES' | translate }}
              </td>
            </tr>
          }
          @for (device of devices; track device) {
            <tr>
              <td class="DevicesTableCell">
                {{ device.metadata.deviceName }}
              </td>
              <td class="DevicesTableCell">
                {{ device.metadata.uuid }}
              </td>
            </tr>
          }
        </table>
      </mat-tab>
    }
    @if (subscriptionType === 'tsd') {
      <mat-tab label="Metrics">
        @if (billing.tsdFlatArray.length) {
          <div class="MetricsContainer">
            <table class="MetricsTable">
              <tr class="semibold">
                <td>
                  {{ 'USR_SETTINGS.DEVICE' | translate }}
                </td>
                <td>
                  {{ 'USR_SETTINGS.UUID' | translate }}
                </td>
                <td>
                  {{ 'USR_SETTINGS.METRIC' | translate }}
                </td>
                <td></td>
              </tr>
              @for (entry of billing.tsdFlatArray; track entry) {
                <tr>
                  <td>
                    {{ entry.deviceName }}
                  </td>
                  <td>
                    {{ entry.uuid }}
                  </td>
                  <td>
                    {{ entry.metric }}
                  </td>
                  <td>
                    <pc-button
                      size="small"
                      variant="secondary"
                      [text]="'USR_SETTINGS.GO_TO_DEVICE' | translate"
                      (btnClick)="
                        ui.openURL(
                          (entry.device.metadata.con_DeviceType === 'EEM-SB370-C'
                            ? this.httpBase.backendUrls.grafanaBaseUrlEmpro
                            : this.httpBase.backendUrls.grafanaBaseUrl) + entry.device.metadata.uuid
                        )
                      "></pc-button>
                  </td>
                </tr>
              }
            </table>
          </div>
        }
      </mat-tab>
    }
  </mat-tab-group>

  @if (subscriptionType !== 'tsd') {
    <pc-button
      variant="secondary"
      [disabled]="authorisation.isViewer(proficloud.currentOrganisation)"
      [text]="'USR_SETTINGS.ASSIGN_DEVICES' | translate"
      (btnClick)="assignDevices()"></pc-button>
  }
  @if (subscriptionType === 'tsd') {
    <pc-button
      variant="secondary"
      [text]="'USR_SETTINGS.ASSIGN_METRICS' | translate"
      [disabled]="authorisation.isViewer(proficloud.currentOrganisation)"
      (btnClick)="billing.showAssignMetricsModal$.next(true)"></pc-button>
  }
</mat-card>

@if (service && availableServicePlans.length > 0) {
  <flex-row style="flex-wrap: wrap; padding: 40px; margin-bottom: 100px">
    <!--left content -->
    <flex-col style="width: 100%; max-width: 100%">
      <!-- header -->
      <flex-row style="width: 100%; max-width: 800px">
        <!-- left: icon -->
        <flex-col-center-center
          style="
            border: 1px solid #eee;
            min-width: 60px;
            width: 60px;
            height: 60px;
            padding: 30px;
            border-radius: 100%;
            background: #f5f5f5;
            margin-right: 20px;
          ">
          <img [src]="service.fields.icon.fields.file.url" color="#222" style="width: 100%; height: 100%" />
        </flex-col-center-center>
        <!-- right: description -->
        <flex-col-center-start>
          <div class="semibold" style="font-size: 24px; margin-bottom: 15px">{{ service.fields.name }}</div>
          <div style="font-size: 16px; margin-bottom: 15px">{{ service.fields.shortDescription }}</div>
          <div style="font-size: 20px; font-weight: light">{{ service.fields.startsAt }}</div>
        </flex-col-center-start>
      </flex-row>
      <!-- plans -->
      <div style="margin-top: 60px; margin-bottom: 60px">
        <h2 class="h2-500">{{ 'STORE.PRICING' | translate }}</h2>
        <br />
        <!-- plan container -->
        <flex-row style="flex-wrap: wrap; padding-left: 5px">
          @for (plan of availableServicePlans; track plan) {
            @if (plan.fields.productionReadiness || app.environment.frontend === 'dev' || app.environment.frontend === 'staging') {
              <div
                [ngClass]="{ 'paper-2': plan.fields.highlighted, 'paper-1': !plan.fields.highlighted }"
                [style.transform]="plan.fields.highlighted ? 'scale(1.1)' : 'scale(1)'"
                [style.margin-left.px]="plan.fields.highlighted ? 10 : 0"
                [style.margin-right.px]="plan.fields.highlighted ? 30 : 20"
                style="border-radius: 5px; width: 270px; margin-top: 20px; margin-bottom: 30px">
                <!-- most popular (if highlighted) -->
                @if (plan.fields.highlighted) {
                  <flex-row-center
                    class="semibold"
                    style="border-top-left-radius: 5px; border-top-right-radius: 5px; padding: 5px; font-size: 16px; color: white; background: var(--primary)"
                    >{{ plan.fields.highlight }}</flex-row-center
                  >
                }
                <div class="paddedContent" style="padding: 20px">
                  <!-- Name -->
                  <flex-row-center>
                    <div style="font-weight: 500; font-size: 20px; margin: 10px 0">{{ plan.fields.planVariantName }}</div>
                  </flex-row-center>

                  <!-- Price -->
                  <flex-row-center-end class="semibold" style="margin-bottom: 15px">
                    <div style="height: 60px; font-size: 60px; color: #0198a1; height: 60px">{{ 'STORE.CURRENCY' | translate }} {{ plan.fields.price }}</div>
                  </flex-row-center-end>
                  <flex-row-center>
                    <flex-row-center style="font-size: 14px; color: #333; padding: 0px 20px 10px 20px; margin-bottom: 20px; border-bottom: 1px solid #ccc">
                      per {{ plan.fields.period }}</flex-row-center
                    >
                  </flex-row-center>

                  <!-- Punchline -->
                  <flex-row-center style="color: var(--jet-black); text-align: center">
                    {{ plan.fields.subTitle }}
                  </flex-row-center>

                  <!-- Bullets -->
                  <flex-col style="margin-top: 20px">
                    @for (bullet of plan.fields.specifications; track bullet) {
                      <flex-row style="padding: 10px 0">
                        <app-icon name="check" style="min-width: 20px; min-height: 20px; width: 20px; height: 20px; margin-right: 10px" color="#222"></app-icon>
                        <div>{{ bullet }}</div>
                      </flex-row>
                    }
                  </flex-col>

                  <!-- New: quantity -->
                  <app-quantity-select #quantitySelect [style.display]="plan.fields.multipleOrderAllowed && plan.fields.preview ? 'block' : 'none'">
                  </app-quantity-select>

                  <!-- Add service to account (primary action ) -->
                  <flex-row-center style="margin-top: 20px">
                    @if (billing.getButtonDetails(service, plan); as buttonDetails) {
                      <pc-button
                        [disabled]="buttonDetails.disabled"
                        [variant]="plan.fields.highlighted ? 'primary' : 'secondary'"
                        [text]="buttonDetails.wording"
                        [isLoading]="loadingStates[plan.fields.sapNumber]"
                        (btnClick)="onAddServiceClick({ service: service, plan: plan, quantity: quantitySelect.quantity })"></pc-button>
                    }
                  </flex-row-center>
                </div>
              </div>
            }
          }
        </flex-row>
      </div>
      <!-- end of plans container -->
      <!-- asterix -->
      <div style="color: var(--regent-gray); margin-bottom: 30px">
        <div [innerHTML]="service.fields.pricingAndAvailability | markdown"></div>
      </div>
      <br /><br />
      <!-- current subscription (check of array length instead -->
      @if (getServiceSubscriptions(service.fields.name); as subscriptions) {
        @if (subscriptions.length > 0) {
          <div style="margin-bottom: 40px">
            <h2 class="semibold" style="margin: 5px 100px 5px 0">{{ 'STORE.YOUR_SUBSCRIPTIONS' | translate }}</h2>
            @for (subscription of [subscriptions[0]]; track subscription) {
              <div>
                @if (billing.getPackageById(subscription.bookedPackageId); as package) {
                  <div>
                    {{ package.name }}:&nbsp;&nbsp;
                    @if (billing.countPackageById(subscription.bookedPackageId); as count) {
                      <a routerLink="/services/account/user-settings" style="color: var(--primary); text-decoration: underline; cursor: pointer">
                        {{ count }} subscription
                        @if (count > 1) {
                          <span>s</span>
                        }
                      </a>
                    }
                  </div>
                }
              </div>
            }
          </div>
        }
      }
      <!-- change log -->
      <flex-row-start-center>
        <h2 class="semibold" style="margin: 5px 100px 5px 0">{{ 'STORE.NEW_FUNCTIONALITIES' | translate }}</h2>
        <span style="color: var(--primary); text-decoration: underline; cursor: pointer" (click)="showChangelog = true">{{
          'STORE.SHOW_PREVIOUS_VERSIONS' | translate
        }}</span>
      </flex-row-start-center>
      @for (version of service.fields.versions; track version) {
        <div>
          @if (version.fields.islatest; as latest) {
            <div>
              <!-- latest -->
              <div style="margin-bottom: 15px; color: #aaa">Version {{ version.fields.version }}, Update from {{ version.fields.release | date }}</div>
              @for (change of version.fields.changelog; track change) {
                <flex-row>
                  <app-icon name="check" style="min-width: 20px; min-height: 20px; width: 20px; height: 20px; margin-right: 10px" color="#222"></app-icon>
                  <div>
                    {{ change }}
                  </div>
                </flex-row>
              }
            </div>
          }
        </div>
      }
      <br /><br /><br />
      <!-- end of changelog -->
      @if (showChangelog) {
        <pc-overlay [config]="{ title: 'Version History' }" (closeClick)="showChangelog = false">
          <ng-container body>
            @for (version of service.fields.versions; track version) {
              <div style="width: 100%">
                <flex-row-between style="border-bottom: 1px solid #aaa; padding: 20px 0 10px 0px; width: 100%">
                  <b>{{ version.fields.version }}</b>
                  <span>{{ version.fields.release | date }}</span>
                </flex-row-between>
                @for (change of version.fields.changelog; track change) {
                  <flex-row style="padding: 10px 0; width: 100%">
                    <app-icon name="check" style="min-width: 20px; min-height: 20px; width: 20px; height: 20px; margin-right: 10px" color="#222"></app-icon>
                    <div>
                      {{ change }}
                    </div>
                  </flex-row>
                }
              </div>
            }
          </ng-container>
        </pc-overlay>
      }
      <!-- ABOUT this service -->
      <h2 class="semibold">{{ 'STORE.ABOUT_THIS_SERVICE' | translate }}</h2>
      <div [innerHTML]="service.fields.serviceDescription | markdown" style="max-width: 800px" class="markdown"></div>
      <!-- service tags -->
      <flex-row>
        @for (tag of service.fields.tags; track tag) {
          <div style="padding: 5px 10px; background: #2f2f2f; margin-right: 10px; color: white; border-radius: 10px">
            {{ tag }}
          </div>
        }
      </flex-row>
    </flex-col>
    <!-- gallery -->
    <div style="margin-top: 60px; margin-bottom: 60px; width: 100%">
      <h2 class="semibold">{{ 'STORE.SCREENSHOTS_TUTORIALS_VIDEOS' | translate }}</h2>
      <flex-col style="width: 100%; max-width: 800px">
        <!-- big image or video -->
        @if (userSelectedMediaType === 'image') {
          <img [src]="userSelectedMedia.fields.file.url" style="width: 100%" />
        }
        @if (userSelectedMediaType === 'video') {
          <video [src]="userSelectedMedia.fields.file.url" style="width: 100%" [attr.type]="userSelectedMedia.fields.file.contentType" controls></video>
        }
        <!-- caption -->
        <div style="padding: 20px 40px">{{ userSelectedMedia.fields.title }}</div>
        <!-- thumbnails -->
        <flex-row-around>
          @for (medium of service.fields.serviceMedia; track medium) {
            <div-relative class="paper-1" style="width: 28%; cursor: pointer; margin: 10px" (click)="selectUserMedium(medium)">
              @if (medium.fields) {
                @if (medium.fields.file.contentType !== 'video/mp4') {
                  <img style="width: 100%" [src]="medium.fields.file.url" />
                }
                @if (medium.fields.file.contentType === 'video/mp4') {
                  <video style="width: 100%; max-width: 100%; pointer-events: none" [src]="medium.fields.file.url"></video>
                }
                @if (medium.fields.file.contentType === 'video/mp4') {
                  <flex-col-center-center class="playIcon" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%">
                    <app-icon name="play" style="width: 30px; height: 30px" color="#222"></app-icon>
                  </flex-col-center-center>
                }
              }
            </div-relative>
          }
        </flex-row-around>
      </flex-col>
    </div>
    <!-- end of gallery -->
    <!-- compatible devices -->
    <div style="margin-top: 60px; margin-bottom: 60px; width: 100%">
      <h2 class="semibold">{{ 'STORE.COMPATIBLE_DEVICES' | translate }}</h2>
      <table style="border-collapse: collapse">
        <tr style="background: var(--alabaster)">
          <td style="border: 1px solid var(--black-haze); padding: 8px 15px">{{ 'STORE.DEVICE' | translate }}</td>
          <td style="border: 1px solid var(--black-haze); padding: 8px 15px">{{ 'STORE.DEVICE_TYPE' | translate }}</td>
          <td style="border: 1px solid var(--black-haze); padding: 8px 15px">{{ 'STORE.ARTICLE_NUMBER' | translate }}</td>
        </tr>
        @for (device of availableDevices; track device) {
          <tr>
            <td style="border: 1px solid var(--black-haze); padding: 8px 15px">{{ device.device_type }}</td>
            <td style="border: 1px solid var(--black-haze); padding: 8px 15px">{{ device.category }}</td>
            <td style="border: 1px solid var(--black-haze); padding: 8px 15px">{{ device.article }}</td>
          </tr>
        }
      </table>
    </div>
    <!-- end of compatible devices -->
    <!-- contact & support -->
    <div style="margin-top: 60px; margin-bottom: 60px; width: 100%">
      <h2 class="semibold">{{ 'STORE.SUPPORT_AND_CONTACT' | translate }}</h2>
      <div>
        <a style="color: var(--primary)" [href]="service.fields.supportAddress">{{ service.fields.support }}</a>
      </div>
    </div>
    <!-- end of contact & support -->
  </flex-row>
}
<!-- TODO: Add Loading Spinner-->
@if (availableServicePlans.length < 1) {
  <div class="loading-spinner">
    <div class="lds-dual-ring styles-overlay-content-icon"></div>
  </div>
}

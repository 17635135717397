<pc-overlay [config]="{ title: 'Assign Devices' }" (closeClick)="billing.showEmmaAssignDevicesModal$.next(false)">
  <ng-container body style="color: pink">
    @if (!confirming) {
      <div style="width: 100%">
        @if (config?.candidateDevices?.length === 0) {
          <div style="width: 100%">
            <div style="font-size: 20px; margin: 20px 0">{{ 'STORE.PLEASE_ADD_COMPATIBLE_DEVICES' | translate }}</div>
            <div style="margin-top: 20px">
              <pc-button
                [text]="'STORE.OPEN_FAQ' | translate"
                (btnClick)="ui.openURL('https://proficloud.io/knowledge-hub/faqs/general/which-device-can-use-which-smart-service/')"></pc-button>
            </div>
          </div>
        }
        @if (config?.candidateDevices?.length > 0) {
          <div style="width: 100%">
            @if (router.url.indexOf('user-settings') < 0) {
              <flex-row class="info">
                <div>
                  <app-icon [name]="'error'" [color]="'#FAFAFA'" class="icon" style="width: 20px; height: 20px"></app-icon>
                </div>
                <div style="padding-left: 10px">
                  {{ 'STORE.ALSO_ASSIGN' | translate }}
                </div>
              </flex-row>
            }
            <div
              style="padding-bottom: 10px"
              [innerHTML]="
                'USR_SETTINGS.GENERAL_SLOT_INFORMATION'
                  | translate
                    : {
                        param1: emma.currentlyAssignedDevicesForSubscription(config.subscription.id),
                        param2: billing.subscribedEmmaDevicesTotal.totalSubscriptionSlots
                      }
              "></div>
            <div>
              <!-- Filter -->
              <div class="filter-container">
                <app-icon class="search-icon" [color]="'gray'" name="search"> </app-icon>
                <input class="filter-input" (input)="emma.searchInput$.next($event.target.value)" placeholder="Search" />
              </div>
            </div>
            <table style="width: 100%; border-collapse: collapse">
              <tr style="background: var(--alabaster)">
                <td style="border: 1px solid var(--black-haze); padding: 15px">{{ 'STORE.DEVICE' | translate }}</td>
                <td style="border: 1px solid var(--black-haze); padding: 15px; text-align: center">
                  {{ 'STORE.ASSIGNED' | translate }}
                </td>
              </tr>
              @for (device of config.candidateDevices; track device) {
                @if (!device.filteredOut && deviceAssignedState(deviceAsEmmaAvailableDevice(device), config.subscription); as added) {
                  <tr class="candidate-device">
                    <td class="device-table-cell" [title]="device.name">
                      {{ device.name | truncate: [25] }}
                    </td>
                    <td class="device-table-cell">
                      <flex-row-center style="transform: scale(1)">
                        <mat-checkbox
                          class="checkbox"
                          [checked]="added === 'here' || added === 'elsewhere'"
                          [disabled]="
                            added === 'elsewhere' ||
                            added === 'here' ||
                            !!(!subscriptionEndpointIds.includes(device.device_id) && subscriptionEndpointIds.length >= config.subscription.quantity)
                          "
                          (change)="onCheckChange($event.checked, device.device_id)"></mat-checkbox>
                        @if (added === 'elsewhere') {
                          <span class="other">to another package</span>
                        }
                      </flex-row-center>
                    </td>
                  </tr>
                }
              }
            </table>
            <flex-row-end style="margin-top: 20px">
              <pc-button
                [inputID]="'perform-assign-devices'"
                [disabled]="!endpointsToAdd"
                size="small"
                [text]="'STORE.ASSIGN_DEVICES' | translate"
                (btnClick)="confirming = true"></pc-button>
            </flex-row-end>
          </div>
        }
      </div>
    }

    @if (confirming) {
      <div style="width: 100%">
        <div class="warning">
          <h2>{{ 'SHARED.CANT_UNDO' | translate }}</h2>
        </div>
        <flex-row>
          <app-icon [name]="'error'" color="#0098a1" style="width: 20px; height: 20px; padding-right: 10px"> </app-icon>
          {{ 'STORE.CONFIRM_ASSIGN_DEVICES' | translate }}
        </flex-row>
        <flex-row style="justify-content: space-between; margin-top: 20px">
          <pc-button variant="secondary" [text]="'SHARED.CANCEL' | translate" (btnClick)="confirming = false"></pc-button>
          <pc-button
            id="confirm-emma-device-assignment"
            [text]="'SHARED.CONFIRM' | translate"
            (btnClick)="billing.showEmmaAssignDevicesModal$.next(false); assignDevices()"></pc-button>
        </flex-row>
      </div>
    }
  </ng-container>
</pc-overlay>
